import { useRecoilState } from 'recoil';
import { loadingState } from '../atoms/loading';
import { useRef, useCallback } from 'react';

// Minimum time the loading indicator should be visible to prevent flickering
// and provide better UX for quick operations
const MINIMUM_LOADING_TIME = 1000;

export const useLoading = () => {
  const [isLoading, setIsLoading] = useRecoilState(loadingState);
  // Track when loading started to calculate minimum display time
  const loadingStartTime = useRef<number | null>(null);
  // Store timeout reference to clear it if loading is shown again
  const hideTimeout = useRef<NodeJS.Timeout | null>(null);

  const showLoading = useCallback(() => {
    // Cancel any pending hide operations
    if (hideTimeout.current) {
      clearTimeout(hideTimeout.current);
      hideTimeout.current = null;
    }

    loadingStartTime.current = Date.now();
    setIsLoading(true);
  }, [setIsLoading]);

  const hideLoading = useCallback(() => {
    if (!loadingStartTime.current) {
      setIsLoading(false);
      return;
    }

    const currentTime = Date.now();
    const elapsedTime = currentTime - loadingStartTime.current;

    // If minimum time has passed, hide immediately
    if (elapsedTime >= MINIMUM_LOADING_TIME) {
      setIsLoading(false);
      loadingStartTime.current = null;
    } else {
      // Calculate remaining time to meet minimum display duration
      const remainingTime = MINIMUM_LOADING_TIME - elapsedTime;

      if (hideTimeout.current) {
        clearTimeout(hideTimeout.current);
      }

      // Schedule hiding after the remaining time
      hideTimeout.current = setTimeout(() => {
        setIsLoading(false);
        loadingStartTime.current = null;
        hideTimeout.current = null;
      }, remainingTime);
    }
  }, [setIsLoading]);

  return {
    isLoading,
    showLoading,
    hideLoading,
  };
};
