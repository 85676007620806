import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

import Player from '../components/PlayerViewer/Player';
import GMap from '../components/GMapViewer/GMap';
import Table from '../components/Table/Table';
import { VideoInfo, videoListState } from '../atoms/video';
import useAuth from '../hooks/useAuth';
import ContentLayout from '../layout/ContentLayout/ContentLayout';
import { Drawer, Button, Splitter, Flex } from 'antd';

const Viewer: React.FC = () => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();

  const [videoList, setVideoList] = useRecoilState(videoListState);
  const [currentSegment, setCurrentSegment] = useState<VideoInfo | null>(null);
  const [selectedVideo, setSelectedVideo] = useState<string>('');
  const [segmentStartTime, setSegmentStartTime] = useState<string>('');
  const [segmentEndTime, setSegmentEndTime] = useState<string>(''); // Added this state

  const [isDrawerVisible, setDrawerVisible] = useState(false);

  const toggleDrawer = () => {
    setDrawerVisible(!isDrawerVisible);
  };

  const handleMarkerClick = (segment: VideoInfo) => {
    setSelectedVideo(segment.fileName);
    setSegmentStartTime(segment.startTime);
    setSegmentEndTime(segment.endTime);
    setCurrentSegment(segment);
  };

  const handleRowClick = (segment: VideoInfo) => {
    handleMarkerClick(segment);
    toggleDrawer();
  };

  const handleSegmentEnd = (nextSegmentIndex: number) => {
    console.log('Segment ended, playing next segment:', nextSegmentIndex); // Debugging log
    if (nextSegmentIndex < videoList.length) {
      const nextSegment = videoList[nextSegmentIndex];
      setSelectedVideo(nextSegment.fileName);
      setSegmentStartTime(nextSegment.startTime);
      setSegmentEndTime(nextSegment.endTime);
      setCurrentSegment(nextSegment);
    }
  };

  useEffect(() => {
    if (!loading && !user) {
      navigate('/auth');
    }
  }, [loading, user, navigate]);

  return (
    <ContentLayout>
      <Splitter>
        <Splitter.Panel defaultSize="65%" min="20%" max="70%">
          <Flex
            justify="left"
            align="left"
            style={{ height: '100%', width: '100%' }}
          >
            <Player
              selectedVideo={selectedVideo}
              segmentStartTime={segmentStartTime}
              segmentEndTime={segmentEndTime} // Pass segmentEndTime to Player
              onSegmentEnd={handleSegmentEnd}
              segmentTitle={currentSegment?.title}
              segmentFileName={currentSegment?.fileName}
            />
          </Flex>
        </Splitter.Panel>
        <Splitter.Panel>
          <Flex justify="center" align="center">
            <GMap
              handleMarkerClick={handleMarkerClick}
              activeSegment={currentSegment}
            />
          </Flex>
        </Splitter.Panel>
      </Splitter>
      <Drawer
        placement="bottom"
        height="85%"
        closable={true}
        onClose={toggleDrawer}
        open={isDrawerVisible}
        styles={{
          body: {
            padding: 0,
            position: 'relative',
            overflow: 'hidden',
            zIndex: 2,
          },
        }}
      >
        <Table
          activeSegment={currentSegment}
          onClick={handleRowClick}
          isEditable
        />
      </Drawer>
      {!isDrawerVisible && (
        <Button
          type="primary"
          style={{
            position: 'absolute',
            width: '100px',
            bottom: '-0.8%',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            borderRadius: '8px 8px 0 0',
            boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
            transition: 'bottom 0.3s ease',
            background: '#31333980',
          }}
          onClick={toggleDrawer}
        >
          Show Data
        </Button>
      )}
    </ContentLayout>
  );
};

export default Viewer;
