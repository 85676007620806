import React from 'react';
import { useRecoilValue } from 'recoil';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import Viewer from './pages/Viewer';
import Editor from './pages/Editor';
import useAuth from './hooks/useAuth';
import Layout from './layout/Layout';
import LoadingOverlay from './components/LoadingOverlay/LoadingOverlay';
import { loadingState } from './atoms/loading';

import './App.css';

const App: React.FC = () => {
  const { user, loading } = useAuth();
  const isLoading = useRecoilValue(loadingState);

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <Router>
        <Layout>
          <Routes>
            <Route path="/auth" element={<LoginPage />} />
            <Route path="/viewer" element={<Viewer />} />
            <Route path="/editor" element={<Editor />} />
            <Route path="/" element={user ? <Viewer /> : <LoginPage />} />
          </Routes>
        </Layout>
      </Router>
    </>
  );
};

export default App;
