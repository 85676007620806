import React from 'react';
import { createPortal } from 'react-dom';
import './LoadingOverlay.css';

const LoadingOverlay: React.FC = () => {
  return createPortal(
    <div className="loading-overlay">
      <div className="loading-spinner"></div>
    </div>,
    document.body,
  );
};

export default LoadingOverlay;
